/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../css/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
import $ from 'jquery';
import 'bootstrap';
import flatpickr from "flatpickr"
import { Polish } from "flatpickr/dist/l10n/pl.js";

$(document).ready(function(){
    var _bind = function() {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover({html: true});

        flatpickr('.js-datepicker', {
            locale: Polish,
            enableTime: true,
            dateFormat: "Y-m-d H:i:s"
        });

        flatpickr('.js-datentpicker', {
            locale: Polish,
            dateFormat: "Y-m-d"
        });
    };

    var _dispose = function() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="popover"]').popover('dispose');
    };

    _bind();

    $(document).on('click', '.do-ajax', function(e){
        e.preventDefault();

        var $t = $(this);

        _dispose();

        $t.find('i').prop('class','').addClass('fa fa-circle-o-notch fa-spin fa-fw');

        $.ajax({
            url : $t.attr('href'),
            type: 'GET',
            success: function(html) {
                $t.closest('tr').replaceWith(html);

                _bind();
            }
        });
    });
});